/** @jsx jsx */
import { Box, Flex, jsx } from 'theme-ui';
import { Link } from 'gatsby';
import { PageFiltersProps } from './types';

const PageFilters: React.FC<PageFiltersProps> = ({ items }) => (
  <Box variant="pageFilters.wrapper">
    <Box variant="pageFilters.content">
      <Flex as="ul" variant="pageFilters.list">
        {items.map(({ label, to, key }) => (
          <Flex as="li" {...{ key }} variant="pageFilters.listItem">
            <Link
              {...{ to }}
              activeClassName="is-active"
              sx={{ variant: 'pageFilters.link' }}
            >
              {label}
            </Link>
          </Flex>
        ))}
      </Flex>
    </Box>
  </Box>
);

export default PageFilters;
