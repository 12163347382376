import routes from '../../../routes';
import { PageFilterItem } from '../../molecules/PageFilters/types';

import { ArticlesCarouselItem } from '../../organisms/ArticlesCarousel/types';
import { InsightPageProps } from './types';

export const filtersStaticItem: PageFilterItem = {
  label: 'All',
  to: routes.INSIGHT,
  key: '1',
};

export const getInsightRelatedLinksItems = (
  data: InsightPageProps['data'],
): ArticlesCarouselItem[] => [
  {
    tag: 'Name Surname  /  07.06.18',
    title: 'Title of max 75 characters 1',
    to: routes.WORK,
    description:
      'Intro para to the story goes here with no more than 130 characters in total and more more than four lines dano nammy sif gikl logn spec',
    image: {
      fluid: data.picInsight01.childImageSharp.fluid,
    },
    key: '1',
  },
  {
    tag: 'Name Surname  /  07.06.18',
    title: 'Title of max 75 characters 2',
    to: routes.WORK,
    description:
      'Intro para to the story goes here with no more than 130 characters in total and more more than four lines dano nammy sif gikl logn spec',
    image: {
      fluid: data.picInsight02.childImageSharp.fluid,
    },
    key: '2',
  },
  {
    tag: 'Name Surname  /  07.06.18',
    title: 'Title of max 75 characters 3',
    to: routes.WORK,
    description:
      'Intro para to the story goes here with no more than 130 characters in total and more more than four lines dano nammy sif gikl logn spec',
    image: {
      fluid: data.picInsight03.childImageSharp.fluid,
    },
    key: '3',
  },
  {
    tag: 'Name Surname  /  07.06.18',
    title: 'Title of max 75 characters 4',
    to: routes.WORK,
    description:
      'Intro para to the story goes here with no more than 130 characters in total and more more than four lines dano nammy sif gikl logn spec',
    image: {
      fluid: data.picInsight04.childImageSharp.fluid,
    },
    key: '4',
  },
];
