import React from 'react';
import { Box, Flex, Spinner } from 'theme-ui';
import InfiniteScroll from 'react-infinite-scroll-component';
import RevealAnimationWrapper from '../../atoms/RevealAnimationWrapper';
import ArticleTile from '../../molecules/ArticleTile';
import { ArticlesListingProps } from './types';

const ArticlesListing: React.FC<ArticlesListingProps> = ({
  items,
  nextPage,
  isLastPage,
}) => (
  <Box variant="articlesListing.wrapper">
    <InfiniteScroll
      dataLength={items.length}
      next={nextPage}
      hasMore={!isLastPage}
      loader={
        <Flex variant="styles.spinnerWrapper">
          <Spinner variant="styles.spinner" />
        </Flex>
      }
      scrollThreshold={0.6}
      hasChildren
    >
      <RevealAnimationWrapper cascade damping={0.05}>
        <ul>
          {items.map(({ key, isFeatured, ...item }) => (
            <li {...{ key }} className={isFeatured ? 'featured' : ''}>
              <ArticleTile {...{ isFeatured, ...item }} />
            </li>
          ))}
        </ul>
      </RevealAnimationWrapper>
    </InfiniteScroll>
  </Box>
);

export default ArticlesListing;
