import React, { useCallback, useMemo, useState } from 'react';
import noop from 'lodash/noop';
import { Container } from 'theme-ui';
import SEO from '../components/atoms/SEO';
import PageFilters from '../components/molecules/PageFilters';
import ArticlesListing from '../components/organisms/ArticlesListing';
import { ArticlesListingItem } from '../components/organisms/ArticlesListing/types';
import InfoBanner from '../components/organisms/InfoBanner';
import { infoBannerItems } from '../components/pages/contact/consts';
import { filtersStaticItem } from '../components/pages/insights/consts';
import { InsightsTemplateProps } from '../components/pages/insights/types';
import {
  getInsightsTags,
  getPaginatedItems,
} from '../components/pages/insights/utils';
import PageSection from '../components/pages/Section';

const InsightsPage: React.FC<InsightsTemplateProps> = ({ pageContext }) => {
  const paginatedItems = useMemo(() => getPaginatedItems(pageContext.posts), [
    pageContext.posts,
  ]);
  const [page, setPage] = useState(0);
  const [isLastPage, setIsLastPage] = useState(paginatedItems.length <= 1);
  const [items, setItems] = useState<ArticlesListingItem[]>(
    paginatedItems[page],
  );
  const filters = useMemo(() => getInsightsTags(pageContext.tags), [
    pageContext.tags,
  ]);

  const nextPage = useCallback(() => {
    const newPage = page + 1;
    setPage(newPage);
    if (newPage < paginatedItems.length) {
      setItems((prevItems) => [...prevItems, ...paginatedItems[newPage]]);
    }
    if (newPage === paginatedItems.length - 1) {
      setIsLastPage(true);
    }
  }, [page, paginatedItems]);

  return (
    <>
      <SEO prefix="Insights" />
      <Container>
        <PageSection>
          <PageFilters items={[filtersStaticItem, ...filters]} />
          <ArticlesListing
            totalItems={pageContext.posts.length}
            {...{ items, nextPage, isLastPage }}
          />
        </PageSection>
      </Container>
      <InfoBanner
        items={infoBannerItems}
        modal={{
          title: 'Sign up for email updates',
          onSubmit: noop,
          formId: '89038835-b441-49f5-8f40-3de3bd752b96',
          portalId: '4072259',
        }}
      />
    </>
  );
};

export default InsightsPage;
